<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="cui__utils__heading mb-0">
          <strong>Image Creatives</strong>
        </div>
      </div>
      <div class="card-body">
        <CreativesDraggable type="image" :fileList="fileList" :receivedCreatives="receivedCreatives" />
        <div class="msg-box msg-box-flex">
          <a-icon class="mx-2" style="fontSize: 200%; opacity: 70%; color: #5481e1;" type="info-circle" />
          <p class="mt-3 ml-2">
            Upload <strong>PNG</strong> or <strong>JPG/JPEG</strong> file only.
          </p>
        </div>
      </div>
    </div>
    <b-container class="overflow-auto" fluid>
      <!-- Ad Interface controls -->
      <a-row class="my-2">
        <a-col :xl="4" :md="12" :xs="24">
        </a-col>
      </a-row>
      <b-row>
        <b-col class="my-1" md="6">
          <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
            <perPageSelect v-model="perPage" :storageKey="'ortb-image-library'" :pageOptions="pageOptions" />
            <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
            <columnSelect :storageKey="'display-images-columns'" :options="fields"></columnSelect>
            <!-- <CreateButton module="display" target="bannerad" /> -->
          </b-form-group>
        </b-col>
        <b-col class="my-1 d-flex flex-row-reverse" md="6">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :bordered="true"
        :current-page="currentPage"
        :fields="filteredFields"
        :hover="true"
        :items="receivedCreatives"
        :per-page="perPage"
        :small="true"
        :striped="true"
        show-empty
        stacked="md"
        class="mt-3"
      >
        <template #cell(Name)="data">
          <div class="d-block d-sm-none">
            <div style="max-width: 200px; min-width: 140px; word-wrap: break-word;">
              {{ data.item.Name }}
            </div>
          </div>
          <div class="d-none d-sm-block">
            {{ data.item.Name }}
          </div>
        </template>
        <template #cell(CampaignName)="data">
          <div style="max-width: 200px; min-width: 140px; word-wrap: break-word;" class="d-block d-sm-none">
            {{ data.item.Name }}
          </div>
          <div class="d-none d-sm-block">
            <div>
              <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length<20">{{ data.item.CampaignName }}</div>
              <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length>=20">{{ data.item.CampaignName.substring(0,20)+".." }}</div>
            </div>
          </div>
        </template>
        <template #cell(Dimensions)="data">
          <div class="">{{ data.item.Width }}x{{ data.item.Height }}</div>
        </template>
        <template #cell(Ratio)="data">
          {{ data.item.WidthRatio }}:{{ data.item.HeightRatio }}
        </template>
        <template #cell(FileSize)="data">
          {{ bytesToSize(data.item.FileSize) }}
        </template>
        <template #cell(Type)="data">
          {{ creativeType(data.item.Type) }}
        </template>
        <template #cell(MediaRating)="data">
          <!-- {{ convertMediaRating(data.item.MediaRating) }} -->
          {{ convertMediaRating(data.item.MediaRating) }}
        </template>
        <template #cell(Preview)="data">
          <img class="picture-block pointer" @click="imagePreview = (link + data.item.DownloadUrl)" :src="link + data.item.DownloadUrl">
        </template>
        <template #cell(actions)="data">
          <router-link class="add-button" @click.stop :to="{ name: 'display-creative-update', params: { id: data.item.Id },  }">
            <b-button
              class="btn-info mr-1"
              size="sm"
              @click="editCreative(data.item.Id)"
            ><i class="fa fa-edit"></i></b-button>
        </router-link>
        </template>
      </b-table>
      <b-row>
        <b-col class="my-1" md="6">
          <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
            }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
        </b-col>
        <b-col class="my-1 d-flex flex-row-reverse" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { createCreative, updateCreative, getCreatives } from '@/api/display/creative'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'

// import { Modal } from 'ant-design-vue'
export default {
  props: ['fileList'],
  components: {
    columnSelect,
    CreativesDraggable: () => import('@/components/custom/creatives/creativesDraggable/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      fileListValue: this.fileList,
      imagePreviewValue: this.imagePreview,
      imagePreview: false,
      link: process.env.NODE_ENV === 'production' ? window.location.origin : 'https://consoledev.adelion.me',
      receivedCreatives: [],
      fields: [
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          thStyle: 'width: 180px; max-height:200px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center align-middle',
          thStyle: 'width: 50px;',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px',
          thStyle: 'width: 250px',
        },
        {
          key: 'Dimensions',
          label: 'Dimensions',
          show: true,
          headerTitle: 'Dimensions',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Ratio',
          label: 'Ratio',
          show: true,
          headerTitle: 'Ratio',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'FileSize',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Type',
          label: 'Type',
          show: true,
          headerTitle: 'Type',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'MediaRating',
          label: 'Media Rating',
          show: true,
          headerTitle: 'Media Rating',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width:200px; max-height:200px;',
        },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['creative', 'settings']),
    ...mapGetters('creative', ['getCreatives']),
    totalRows() {
      return this.receivedCreatives.length
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
  },
  methods: {
    bytesToSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i === 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
    creativeType(type) {
      if (type === 0) {
        return 'PNG'
      } else if (type === 1) {
        return 'JPEG'
      }
    },
    actionGetCreatives() {
      this.$store.dispatch('creative/LOAD_CREATIVES')
    },
    convertDuration(duration) {
      const minutes = duration < 60 ? '00' : parseInt(duration / 60)
      let seconds = duration % 60 ? duration % 60 : '0'
      if (seconds < 10) { seconds = `0${seconds}` }
      return `${minutes}:${seconds}`
    },

    beforeUpload(file) {
      this.fileListValue = [...this.fileListValue, file]
      this.$emit('before-upload', { fileList: this.fileListValue })
      return false
    },
    emitImagePreview(val) {
      this.imagePreviewValue = val
      this.$emit('image-preview', { imagePreview: this.imagePreviewValue })
    },
    closePreview() {
      this.imagePreview = false
    },
    convertMediaRating(val) {
      if (val === 1) {
        return 'All Audiences'
      } else if (val === 2) {
        return 'Everyone Over 12'
      } else if (val === 3) {
        return 'Mature Audiences'
      } else {
        return undefined
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    editCreative(id) {
      // this.$router.push('/display/campaign/update/' + id)
      this.$router.push({ name: 'display-creative-update', params: { id: id } }).catch(() => {})
    },
    updateCreative(Id, Name, MediaRating) {
      MediaRating = parseInt(MediaRating)
      const payload = { Id, Name, MediaRating }
      updateCreative(payload)
    },
    handleChange(e) {
    //   console.log(e, 'EVENT')
      if (e.fileList.length === 1) {
        const selectedFile = e.file
        // console.log(selectedFile, 'Selektovan Fajl')
        createCreative(selectedFile, selectedFile.type)
          .then(response => {
            this.receivedCreatives.push(response.Creative)
            // console.log(this.receivedCreatives, 'list risivovanih')
          })
      } else if (e.fileList.length > 1) {
        const selectedFile = e.fileList[e.fileList.length - 1].originFileObj
        createCreative(selectedFile, selectedFile.type)
          .then(response => {
            this.receivedCreatives.push(response.Creative)
            // console.log(this.receivedCreatives, 'list risivovanih')
          })
      }
    },
  },
  watch: {
    fileList(val) {
      this.fileListValue = val
    },
    imagePreview(val) {
      this.imagePreviewValue = val
    },
  },
  created() {
    getCreatives().then(response => {
      response.forEach(el => {
        if (el.Mime === 0 || el.Mime === 1) {
          this.receivedCreatives.push(el)
        }
      })
    })
  },
}
</script>

<style>
.msg-box-flex {
  display: flex;
  align-items: center;
  justify-content: start;
}
.msg-box {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 14px;
  margin-top: 15px;
  background-color: #fafafa;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #eae8e8;
}
.z-default {
  position: absolute;
  z-index: 0;
}
.z-above {
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .msg-box-flex {
    font-size: 75%;
  }
}
</style>
